import $ from 'jquery';
import select2 from 'select2';

window.jQuery = $;
window.$ = $;

select2($);

document.addEventListener('turbo:load', function() {
  $('.select2').select2();
});
