// Entry point for the build script in your package.json
import "./add_jquery"
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "./channels"
import "chartkick/chart.js"
import 'select2/dist/css/select2.min.css'

Rails.start()
ActiveStorage.start()

import "./controllers"
