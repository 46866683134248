import { Application } from "@hotwired/stimulus"
import Reveal from 'stimulus-reveal-controller'

const application = Application.start()
import { Tabs } from 'tailwindcss-stimulus-components';
import RemovalsController from './removals_controller.js';
// Configure Stimulus development experience
application.register('reveal', Reveal)
application.register('tabs', Tabs)
application.register('removals', RemovalsController);
application.warnings = true
application.debug    = false
window.Stimulus      = application

export { application }

